import localStorage from 'store'

const ROLE_ADMIN_BK = "Administrator BK"
const ROLE_HELPDESK = "Helpdesk"
const ROLE_DISTRIBUTOR = "Distributor"
const ROLE_TSO = "TSO"
const ROLE_ASM = "ASM"
const ROLE_SSM = "SSM"
const ROLE_GM = "GM"
const ROLE_DISTRIBUTOR_KHUSUS = "Distributor Khusus"
const ROLE_RTM_NON_SEMEN = "RTM Non Semen"
const ROLE_SPC = "SPC"
const ROLE_SPC_GROUP = "SPC Group"
const ROLE_SPC_TARGET = "SPC TARGET"
const ROLE_KAM = "KAM"
const ROLE_CORSALES = "Corsales"
const ROLE_ADMIN_IO = "Admin OI"
const ROLE_TIM_CRM = "TIM CRM"
const ROLE_QC_DataWareHouse = "QC DataWareHouse"
const ROLE_CRM_ATSALESMAN = "CRM ATSalesman"
const ROLE_SALESMAN_DISTRIBUTOR = "Salesman Distributor"

const hasRoles = (value) => {
    const roles = localStorage.get('profile').roles;

    if (!localStorage.get('profile') || (value.length > 0 && roles && !roles.some(e => value.indexOf(e.role) != -1))) {
    return false
    }

    return true
}

const getRoles = () => {
    const roles = localStorage.get('profile').roles;

    return roles.map(item => item.role)
}

const hasPermissions = (value) => {
    const permissions = localStorage.get('profile').actions;

    return !(!localStorage.get('profile') || (value.length > 0 && permissions && !permissions.some(e => value.indexOf(e) != -1)))
}

export {
    hasRoles,
    getRoles,
    hasPermissions,
    ROLE_ADMIN_BK,
    ROLE_HELPDESK,
    ROLE_DISTRIBUTOR,
    ROLE_TSO,
    ROLE_ASM,
    ROLE_SSM,
    ROLE_GM,
    ROLE_DISTRIBUTOR_KHUSUS,
    ROLE_RTM_NON_SEMEN,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    ROLE_SPC_TARGET,
    ROLE_KAM,
    ROLE_CORSALES,
    ROLE_ADMIN_IO,
    ROLE_TIM_CRM,
    ROLE_QC_DataWareHouse,
    ROLE_CRM_ATSALESMAN,
    ROLE_SALESMAN_DISTRIBUTOR,
}